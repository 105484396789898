import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

    static targets = ["messageBoxSuccess", "messageBoxError"]

    connect() {
    }

    checkSnQuery() {
        let query = event.srcElement.value

        let messageBoxSuccess = this.messageBoxSuccessTarget
        let messageBoxError = this.messageBoxErrorTarget

        let data = new FormData()
        data.append("query", query)

        let response = Rails.ajax({
            url: '/tasks/check_sn_query_gl',
            type: 'POST',
            data: data,
            success: function (data) {
                if (data.success) {
                    console.log("success")
                    messageBoxSuccess.classList.remove('invisible')
                    messageBoxError.classList.add('invisible')
                    document.getElementById("msg-url-success").innerHTML = data.message

                } else {
                    messageBoxError.classList.remove('invisible')
                    messageBoxSuccess.classList.add('invisible')
                    document.getElementById("msg-url-error").innerHTML = data.message
                }
            }
        })
    }


}