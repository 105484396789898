import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }
    end(){
        let providers = [...document.querySelectorAll('.selectedProvider')].map(x => x.innerHTML)
        let data = new FormData()
        data.append("fallback", providers)

        Rails.ajax({
            url: this.data.get("url"),
            type:'PUT',
            data:  data
        })
    }
}