import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

    static targets = ["SmartLead"]


  connect() {
  }

    loadCampaigns(event){
        document.getElementById( "list_campaigns").classList.add("hidden");
        //document.getElementById( "push_to_response").classList.add("hidden");
        document.getElementById( "campaignsListLoader").classList.remove("hidden");
        var tool = document.getElementById("tool").value
        Rails.ajax({
            url: "/users/get_campaigns?tool_name=" + tool,
            //url: "/users/get_campaigns?tool_name=X",
            type: 'GET',
            success: function (data) {
                console.log(data);
                if (data.success) {
                    console.log("success")
                    // Get the select element by its name attribute
                    var selectBox = document.querySelector('select[name="list_campaigns"]');

                    // Clear the existing options
                    selectBox.innerHTML = '';

                    // Add new options from the campaigns array
                    data.data.forEach(function(campaign) {
                        // Create a new option element
                        var newOption = document.createElement('option');

                        // Set the text and value for the option
                        newOption.text = campaign.name;  // Displayed text
                        newOption.value = campaign.id;   // Option value


                        // Append the new option to the select box
                        selectBox.add(newOption);
                    });
                    document.getElementById( "list_campaigns").classList.remove("hidden");
                    document.getElementById( "campaignsListLoader").classList.add("hidden");
                    document.getElementById( "campaigns_error").classList.add("hidden");
                   // document.getElementById( "push_to_response").classList.remove("hidden");

                } else {
                    console.log("error")
                    document.getElementById( "campaignsListLoader").classList.add("hidden");
                    document.getElementById( "campaigns").classList.add("hidden");
                    document.getElementById( "push_to_campaign_submit").classList.add("hidden");
                    document.getElementById( "new_campaigns").classList.add("hidden");
                    document.getElementById( "loader" + tool + "Campaigns").classList.add("hidden");
                    document.getElementById( "campaigns_error").classList.remove("hidden");
                    document.getElementById( "campaigns_error_message").innerHTML = data.data;

                    //document.getElementById( event.target.id + "_loading").classList.add("hidden");
                    //console.log("check_" + event.target.id + "_warning")
                    //document.getElementById("check_field_" + event.target.id + "_warning").classList.remove("hidden");
                    //document.getElementById("check_field_" + event.target.id + "_ok").classList.add("hidden");
                    // document.getElementById("check_field_" + event.target.id + "_error").classList.add("hidden");
                    //document.getElementById("btnStep1Next").classList.remove("hidden");
                }
            }
        });
    }

    checkAndPreviewData(event){

        document.getElementById("check_field_" + event.target.id + "_warning").classList.add("hidden");
        document.getElementById("check_field_" + event.target.id + "_ok").classList.add("hidden");
        //document.getElementById("check_field_" + event.target.id + "_error").classList.add("hidden");
        document.getElementById( event.target.id + "_loading").classList.remove("hidden");

        //Common
        const preview = [].slice.call(document.getElementById('preview_data').options).map(x => x.value);
        const selectedValue = event.target.value;

        let previewData = ""
        if(preview[selectedValue] != undefined){
            previewData = preview[selectedValue]
        }
        console.log(event.target.id);

        //Check Data
        console.log(selectedValue);
        if (selectedValue == "") {
            document.getElementById( event.target.id + "_loading").classList.add("hidden");
            document.getElementById("check_field_" + event.target.id + "_warning").classList.add("hidden");
            document.getElementById("check_field_" + event.target.id + "_ok").classList.add("hidden");
            if(event.target.id == "contact_first_name" || event.target.id == "contact_last_name" || event.target.id == "company_name" || event.target.id == "company_domain"){
                //document.getElementById("check_field_" + event.target.id + "_error").classList.remove("hidden");
                document.getElementById("btnStep1Next").classList.add("hidden");
            }
        } else {

            let data = new FormData()
            data.append("preview_data", previewData)
            data.append("mapped_field", event.target.id)
            Rails.ajax({
                url: "/tasks/check_preview_data",
                type: 'POST',
                data: data,
                success: function (data) {
                    if (data.success) {
                        console.log("success")
                        document.getElementById( event.target.id + "_loading").classList.add("hidden");
                        document.getElementById("check_field_" + event.target.id + "_warning").classList.add("hidden");
                        document.getElementById("check_field_" + event.target.id + "_ok").classList.remove("hidden");
                        //document.getElementById("check_field_" + event.target.id + "_error").classList.add("hidden");
                        document.getElementById("btnStep1Next").classList.remove("hidden");
                    } else {
                        document.getElementById( event.target.id + "_loading").classList.add("hidden");
                        console.log("check_" + event.target.id + "_warning")
                        document.getElementById("check_field_" + event.target.id + "_warning").classList.remove("hidden");
                        document.getElementById("check_field_" + event.target.id + "_ok").classList.add("hidden");
                       // document.getElementById("check_field_" + event.target.id + "_error").classList.add("hidden");
                        document.getElementById("btnStep1Next").classList.remove("hidden");
                    }
                }
            });
        }

        //preview
        [].slice.call(previewData.split(',')).forEach((element, index) => document.getElementById(event.target.id + "_preview_"+index).innerHTML = element)
    }



     archiveTask() {

      const taskID = this.element.id;
      const taskItem = "item_task_" + taskID;

     Rails.ajax({
         url: "/tasks/"+taskID+"/archive",
         type:'PUT',
         success: function(data) {
             if (data.success){
                 document.getElementById(taskItem).classList.add('hidden')
             }
         }
     })

  }

  exportFile(){
      //let messageBox = this.messageBoxTarget
      let data = new FormData()
      data.append("deliverable", this.deliverableTarget.checked)
      data.append("catch_all", this.catchAllTarget.checked)
      data.append("not_found", this.notFoundTarget.checked)
      data.append("format", this.formatTarget.value)

      console.log( "Format : " + this.formatTarget.value)

      Rails.ajax({
          url: "/tasks/" + this.taskUuidTarget.id + "/export",
          type:'POST',
          data: data,
          success: function(data) {
                  //messageBox.classList.remove('invisible')
                  //let title = data.success ? "Success" : "Error"
                  //document.getElementById("messageBoxSuccessStatus")._x_model.set(data.success.toString())
                  //document.getElementById("msg-title").innerHTML = title
                  //document.getElementById("msg-content").innerHTML = data.message
                  //setTimeout(() => { messageBox.classList.add('invisible'); }, 5000);

          }
      })
  }

    verifyScrubby(){
        let messageBox = this.messageBoxTarget
        console.log('verify')

        Rails.ajax({
            url: "/tasks/" + this.taskUuidTarget.id + "/scrubby",
            type:'POST',
            success: function(data) {
                messageBox.classList.remove('invisible')
                let title = data.success ? "Success" : "Error"
                document.getElementById("messageBoxSuccessStatus")._x_model.set(data.success.toString())
                document.getElementById("msg-title").innerHTML = title
                document.getElementById("msg-content").innerHTML = data.message
                setTimeout(() => { messageBox.classList.add('invisible'); }, 5000);

            }
        })
    }


}
