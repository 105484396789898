import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ["messageBoxSuccess", "messageBoxError","removedCard"]

  connect() {
  }
  
  removeProvider(){
    let messageBoxSuccess = this.messageBoxSuccessTarget
    let messageBoxError = this.messageBoxErrorTarget
    let provider_id = event.srcElement.id
    console.log(provider_id)
    let data = new FormData()
    data.append("provider_id", provider_id)

  
    let response = Rails.ajax({
      url: '/user_api_credentials/'+ provider_id,
      type:'DELETE',
      data: data, 
      success: function(data) {
        console.log(data)
        if (data.success){
          //document.getElementById("catalog_" + provider_id).classList.remove('hidden')
          //document.getElementById("sync_" + provider_id).classList.add("hidden")
          messageBoxSuccess.classList.remove('invisible')
          document.getElementById("msg-content-remove-success").innerHTML = data.message
          setTimeout(() => { messageBoxSuccess.classList.add('invisible'); }, 5000);

        } else {
          messageBoxError.classList.remove('invisible')
          document.getElementById("msg-content-remove-error").innerHTML = data.message
          setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
        }
      }
    })

  }

  removeProviderLegacy(){

    let removedCard = this.removedCardTarget

    let provider_id = event.srcElement.id
    let data = new FormData()
    data.append("provider_id", provider_id)

    let response = Rails.ajax({
      url: '/user_api_credentials/1',
      type: 'DELETE',
      data: data,
      success: function (data) {
        if (data.success) {
          removedCard.classList.add('hidden')
        }
      }
    })


  }

  }