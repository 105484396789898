import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["manual_selection_checkbox", "manual_providers_management_checkbox","manual_order_checkbox", "provider"]
    static values = { url: String }

  connect() {
  }



     toggleProvider() {

         const availableProviderId = "available_provider_" + this.element.id
         const connectedProviderId = "connected_provider_" + this.element.id

         //console.log(availableProviderId)
         //console.log(connectedProviderId)

         document.getElementById(availableProviderId).classList.toggle("hidden");
         document.getElementById(connectedProviderId).classList.toggle("hidden");


         //Fallback management
         const fallbackProviderId = "fallback_provider_"+this.element.id
         document.getElementById(fallbackProviderId).classList.toggle("hidden");

         const fallbackProviderItemId = "fallback_provider_item_"+this.element.id
         document.getElementById(fallbackProviderItemId).classList.toggle("selectedProvider")

         // Update API Order in back end
         this.updateApiOrder()

      }

    selectProvider() {

        let providerCard = this.providerTarget
        let messageBoxError = this.messageBoxErrorTarget

        // Change class of the provider card
        providerCard.classList.toggle("active-provider-selection");

        document.getElementById("fallback_provider_item_" + providerCard.id).classList.toggle("selectedProvider")

        // Add to fallback
        const fallbackProviderCard = "fallback_provider_" + providerCard.id
        document.getElementById(fallbackProviderCard).classList.toggle("hidden")

    }

  toggleManualProvidersManagement(){
    
    let data = new FormData()
    data.append("manual_providers_management", this.manual_providers_management_checkboxTarget.checked)
    Rails.ajax({
      url: "/users/1/manual_providers_management",
      type:'PUT',
      data:  data, 
      success: function(data) { 
        if (data.success){
          if (data.manual_providers_management){
            document.getElementById('advancedMode').classList.remove('hidden')
            //document.getElementById('automatedMode').classList.add('hidden')
          } else {
            document.getElementById('advancedMode').classList.add('hidden')
            //document.getElementById('automatedMode').classList.remove('hidden')
          } 
        }
      }
    })
  }
  
  toggleManualScope(){
    
    //console.log("Valeur du mode selection : " + this.manual_selection_checkboxTarget.checked)
    let data = new FormData()
    data.append("manual_providers_scope", this.manual_selection_checkboxTarget.checked)
    Rails.ajax({
      url: "/users/1/manual_providers_scope",
      type:'PUT',
      data:  data
    })
  }
  
  toggleManualOrder(){
    console.log("Valeur du mode order : " + this.manual_order_checkboxTarget.checked)

    let data = new FormData()
    data.append("manual_providers_order", this.manual_order_checkboxTarget.checked)
     Rails.ajax({
       url: "/users/1/manual_providers_order",
       type:'PUT',
       data:  data
     })
  }

  updateApiOrder(){
    //console.log("Here we are")
    let providers = [...document.querySelectorAll('.selectedProvider')].map(x => x.innerHTML)
    let data = new FormData()
    data.append("fallback", providers)
 
     Rails.ajax({
       url: this.data.get("url"),
       type:'PUT',
       data:  data
     })
   }

   sliderLimitChange(){
    this.updateUserPreference();
    this.updateDisplayedEmailProviders();
   }

   updateUserPreference(){
    //console.log("Hello from AJAX call")
    let data = new FormData()
    data.append("max_email_cost", parseInt(document.getElementById('max_email_cost_input').value))
  
    Rails.ajax({
      url: this.data.get("url"),
      type:'PUT',
      data:  data
    })
   }

   updateDisplayedEmailProviders(){
     //console.log("max email cost : " + parseInt(document.getElementById('max_email_cost_input').value));
    [...document.querySelectorAll('#email_providers_list > div')].filter(x => parseInt(x.dataset.rate) > parseInt(document.getElementById('max_email_cost_input').value) ).forEach(x => x.classList.add("hidden"));
    [...document.querySelectorAll('#email_providers_list > div')].filter(x => parseInt(x.dataset.rate) <= parseInt(document.getElementById('max_email_cost_input').value) ).forEach(x => x.classList.remove("hidden"));
   }

}
