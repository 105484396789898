import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";
import Rails from "@rails/ujs"

export default class extends Controller {

    static targets = ["messageBox","deliverable","catchAll","notFound","taskUuid","format"]

    initialize() {
        this.submit = debounce(this.submit.bind(this), 350)
    }


    submit() {
        this.element.requestSubmit();
    }
}
