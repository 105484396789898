import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ["messageBoxSuccess", "messageBoxError","addedCard"]

  connect() {
  }
  
  addProvider(event) {
    let messageBoxSuccess = this.messageBoxSuccessTarget
    let messageBoxError = this.messageBoxErrorTarget
    let provider_id  = event.srcElement.id
    console.log(provider_id)
    let api_key_id = "api_key_" + provider_id
    let api_key = document.getElementById(api_key_id).value
    let use_bc_credits_if_ooc = document.getElementById("use_bc_credits_if_ooc_" + provider_id) == null ? false : document.getElementById("use_bc_credits_if_ooc_" + provider_id).checked

    let data = new FormData()
    data.append("provider_id", provider_id)
    data.append("api_key", api_key)
    data.append("use_bc_credits_if_ooc", use_bc_credits_if_ooc)
    console.log(provider_id)
    console.log(api_key)
    console.log(use_bc_credits_if_ooc)
    let response = Rails.ajax({
      url: '/user_api_credentials',
      type:'POST',
      data: data, 
      success: function(data) {

        if (data.success){
          document.getElementById(api_key_id).value = ""
          document.getElementById("catalog_" + provider_id).classList.add('hidden')
          //document.getElementById("sync_" + provider_id).classList.remove("hidden")
          messageBoxSuccess.classList.remove('invisible')
          document.getElementById("msg-content-add-success").innerHTML = data.message
          setTimeout(() => { messageBoxSuccess.classList.add('invisible'); }, 5000);

        } else {
          document.getElementById(api_key_id).value = ""
          messageBoxError.classList.remove('invisible')
          document.getElementById("msg-content-add-error").innerHTML = data.message
          setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
        }

      }
    })



   }

  addProviderLegacy(event) {

    let provider = document.getElementById('provider_name').value
    let api_key = document.getElementById('api_key').value

    let data = new FormData()
    data.append("provider_id", provider)
    data.append("api_key", api_key)
    data.append("use_bc_credits_if_ooc", true)

    let response = Rails.ajax({
      url: '/user_api_credentials',
      type:'POST',
      data: data,
      success: function(data) {
        document.getElementById("returned_message").classList.remove('hidden')
        Rails.$("#returned_message")[0].innerHTML = data.message;
        if (data.success){
          document.getElementById("own_provider_card_" + provider).classList.remove('hidden')
        }
      }
    })
    document.getElementById('api_key').value = ""
    setTimeout(() => { document.getElementById("returned_message").classList.add('hidden'); }, 5000);
  }

  }