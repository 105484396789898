import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

    static targets = ["messageBox"]

    connect() {
        console.log("Admin controller")
    }

    unstuckTask(){
        let messageBox = this.messageBoxTarget
        let taskId  = event.srcElement.id
        console.log(taskId)

        Rails.ajax({
            url: "/activity/unstuck/" + taskId,
            type:'POST',
            success: function(data) {
                messageBox.classList.remove('invisible')
                let title = data.success ? "Success" : "Error"
                document.getElementById("messageBoxSuccessStatus")._x_model.set(data.success.toString())
                document.getElementById("msg-title").innerHTML = title
                document.getElementById("msg-content").innerHTML = data.message
                setTimeout(() => { messageBox.classList.add('invisible'); }, 5000);

            }
        })
    }

    terminateTask(){
        let messageBox = this.messageBoxTarget
        let taskId  = event.srcElement.id
        console.log(taskId)

        Rails.ajax({
            url: "/activity/terminate/" + taskId,
            type:'POST',
            success: function(data) {
                messageBox.classList.remove('invisible')
                let title = data.success ? "Success" : "Error"
                document.getElementById("messageBoxSuccessStatus")._x_model.set(data.success.toString())
                document.getElementById("msg-title").innerHTML = title
                document.getElementById("msg-content").innerHTML = data.message
                setTimeout(() => { messageBox.classList.add('invisible'); }, 5000);

            }
        })
    }

}